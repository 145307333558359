<template lang="pug">
  FormModal(title="New Call to Action", endpoint="blocks", :data="data", v-bind="$attrs", @success="onSuccess")
    InputGroup(label="Text", name="call_to_action_text")
      TextInput(v-model="data.call_to_action_text", prepend-icon="block-cta", auto-focus)
    InputGroup(label="URL", name="content_url")
      TextInput(v-model="data.content_url", prepend-icon="link")
    InputGroup(v-if="!collection_id", name="collection_ids", label="Collection(s) - optional", description="Click the chevron or your down arrow key to see list")
      MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="options/all/collections", option-label="label")
</template>

<script>
export default {
  props: {
    collection_id: {
      type: Number
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      block_type: "cta",
      call_to_action_text: "",
      content_url: ""
    }
  }),
  created() {
    this.data.collection_id = this.collection_id
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Call to action successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
